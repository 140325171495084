import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <div className='container w-screen m-auto mt-20 text-center'>
    <SEO title='404: Not found' />
    <h1 className='text-2xl font-bold'>404 :(</h1>
    <p>looks like you hit a dead end, sorry</p>
  </div>
);

export default NotFoundPage;
